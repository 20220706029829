import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import swal from "sweetalert2";
import { ClipLoader } from "react-spinners";

import reportWebVitals from "./reportWebVitals";
import devfest from "./assets/devfest.svg";
import { apiService } from "./services";
import "./index.css";

const App = () => {
  // State.
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState(0);

  // Effect to check slots.
  useEffect(() => {
    apiService.checkSlot().then((slots) => {
      setSlots(slots.count);
    });

    // Clean up.
    return () => {
      // Do something.
    };
  }, []);

  // Validate email
  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // Handle submit
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      const email = data.get("email")?.toString();
      // Set loading
      setLoading(true);
      if (email && validateEmail(email)) {
        // Check if email is already subscribed
        const res = await apiService.checkEmail(email);
        if (res) {
          swal.fire({
            title:
              "You are already confirmed for the event. See you at the event! Please note that your previous ticket on the event platform will be used to check you in.",
            icon: "info",
          });
        } else {
          // Check if slot is available
          const res = await apiService.checkSlot();
          // Set slots
          setSlots(res.count);
          if (res && res.available) {
            // Add to rsvp list
            await apiService.saveRsvp({
              email,
            });
            swal.fire({
              title:
                "Success! Thank you for confirming your attendance. Please note that your previous ticket on the event platform will be used to check you in.",
              icon: "success",
            });
            // Update slots
            setSlots(res.count - 1);
          } else {
            swal.fire({
              title:
                "Sorry! We are full. You can still join us virtually on the event platform.",
              icon: "info",
            });
          }
        }
        // Set loading
        setLoading(false);
      } else {
        swal.fire({
          title: "Please enter a valid email address",
          icon: "warning",
        });
        // Set loading
        setLoading(false);
      }
      // Reset form
      event.target.reset();
    } catch (error) {
      swal.fire({
        title: "Something went wrong. Please try again",
        icon: "error",
      });
      // Set loading
      setLoading(false);
    }
  };

  return (
    <section className="page">
      <form className="form" onSubmit={handleSubmit}>
        <img src={devfest} alt="devfest" />
        <div className="header">
          <div className="header-title">Redeem Ticket({slots})</div>
          <small>GDG DevFest Rift Valley - 2022</small>
        </div>
        <div className="info">
          Note : Your previous ticket on the event platform will be used to
          check you in. You still need it.
        </div>
        <div className="form-group">
          <label htmlFor="email">
            Email Address used to register for DevFest Rift Valley 2022
          </label>
          <input
            name="email"
            type="email"
            id="email"
            placeholder="type..."
            required={true}
          />
        </div>
        <button disabled={loading ? true : false} className="btn" type="submit">
          Confirm
        </button>
      </form>
      {loading ? (
        <div className="loading">
          <ClipLoader
            loading={loading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : null}
    </section>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
