// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBa0JObdO0VM_aYZUhvismc0e4K7xjPpA",
  authDomain: "rsvp-lite.firebaseapp.com",
  projectId: "rsvp-lite",
  storageBucket: "rsvp-lite.appspot.com",
  messagingSenderId: "424371406503",
  appId: "1:424371406503:web:ca51807b404aea486e3d82",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

interface Rsvp {
  email: string;
}

class Api {
  /**
   * This method will add a new rsvp to the database
   * @method addRsvp
   * @param rsvp
   * @returns
   */
  async saveRsvp(rsvp: Rsvp) {
    try {
      // Add a new rsvp with the email as the id.
      await setDoc(doc(db, "rsvps", rsvp.email), {
        email: rsvp.email,
      });
    } catch (error) {
      throw error;
    }
  }

  /**
   * This method checks if the email is already in the database.
   * @method checkEmail
   */
  async checkEmail(email: string) {
    try {
      const docRef = doc(db, "rsvps", email);
      const docSnap = await getDoc(docRef);
      return docSnap.exists();
    } catch (error) {
      throw error;
    }
  }

  /**
   * This method checks if slot is available.
   * @method checkSlot
   */
  async checkSlot() {
    try {
      // Get number of rsvps, firstore documents
      const querySnapshot = await getDocs(collection(db, "rsvps"));
      return {
        count: 100 - querySnapshot.size > 0 ? 100 - querySnapshot.size : 0,
        available: querySnapshot.size < 120,
      };
    } catch (error) {
      throw error;
    }
  }

  /**
   * This method anonymously signs in the user.
   * @method signInAnonymously
   */
  async signInAnonymously() {
    try {
      await signInAnonymously(auth);
    } catch (error) {
      throw error;
    }
  }
}

const apiService = new Api();

export { apiService };
